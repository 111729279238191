export default [
  {
    title: 'Pelanggan',
    icon: 'UserIcon',
    customicon: `<svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="3" width="15.2" height="18.8" rx="1.5" stroke="black"/>
      <rect x="2.29999" y="1.2" width="15.2" height="18.8" rx="1.5" fill="#E2E2E2" stroke="black"/>
      <path d="M11.8583 16H7.94075C7.07888 16 6.36066 15.9104 5.75343 15.7175C5.53143 15.6486 5.38779 15.4211 5.40085 15.1799C5.56408 13.1195 7.54246 11.5 9.89955 11.5C12.2566 11.5 14.2285 13.1126 14.3982 15.1799C14.4178 15.428 14.2742 15.6486 14.0457 15.7175C13.4384 15.9104 12.7202 16 11.8583 16ZM6.45207 14.8354C6.883 14.925 7.3727 14.9663 7.94075 14.9663H11.8583C12.4264 14.9663 12.9161 14.925 13.347 14.8354C13.001 13.5123 11.5711 12.5337 9.89955 12.5337C8.22804 12.5337 6.79812 13.5123 6.45207 14.8354Z" fill="#292D32"/>
      <path d="M9.90001 11.5C8.40971 11.5 7.20001 10.2855 7.20001 8.7969C7.20001 7.30832 8.40971 6.1 9.90001 6.1C11.3903 6.1 12.6 7.30832 12.6 8.7969C12.6 10.2855 11.3903 11.5 9.90001 11.5ZM9.90001 7.03426C8.92726 7.03426 8.13535 7.82528 8.13535 8.7969C8.13535 9.77476 8.92726 10.5657 9.90001 10.5657C10.8728 10.5657 11.6647 9.77476 11.6647 8.7969C11.6647 7.82528 10.8728 7.03426 9.90001 7.03426Z" fill="#292D32"/>
      </svg>`,
    resource: 'Customer',
    action: 'manage',
    children: [
      {
        title: 'Grup Kontak',
        icon: 'None',
        resource: 'Customer',
        route: 'grup-contact',
        action: 'manage',
      },
      {
        title: 'Data Kontak',
        icon: 'None',
        resource: 'Customer',
        route: 'info-customer',
        action: 'manage',
      },
      {
        title: 'Wawasan',
        icon: 'None',
        resource: 'Customer',
        route: 'data-customer',
        action: 'manage',
      },
    ],
  },
]
