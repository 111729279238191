<!-- eslint-disable vue/no-v-html -->
<template>
  <li
    v-if="canViewVerticalNavMenuLink(item) && item.visible"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
      'komform-highlight': Boolean(item.title === 'Form Order' && komformBadge),
      'komform-highlight': Boolean(item.title === 'Kartu Komcards' && komcardsBadge),
    }"
  >
    <b-link
      v-if="item.title !== 'Inbox'"
      v-bind="linkProps"
      class="d-flex align-items-center"
      :class="{ 'childtitlestyle': Boolean(item.hasParent) }"
    >
      <div
        v-if="item.customicon"
        v-html="item.customicon"
      />
      <feather-icon
        v-else
        :icon="item.icon || (!Boolean(item.hasParent) ? 'CircleIcon' : '')"
      />
      <span
        class="menu-title text-truncate font-weight-bold"
        :class="{ 'text-black': !Boolean(item.hasParent) }"
      >{{ t(item.title) }}</span>
      <b-badge
        v-if="item.title === 'Kendala' && followUp!==0"
        pill
        :variant="'primary'"
        class="mr-1 ml-auto"
      >
        {{ followUp }}
      </b-badge>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-link
      v-if="item.title === 'Inbox' && inboxItems.length > 0"
      v-bind="linkProps"
      class="d-flex align-items-center"
      :class="{ 'childtitlestyle': Boolean(item.hasParent) }"
    >
      <div
        v-if="item.customicon"
        v-html="item.customicon"
      />
      <feather-icon
        v-else
        :icon="item.icon || (!Boolean(item.hasParent) ? 'CircleIcon' : '')"
      />
      <span
        class="menu-title text-truncate font-weight-bold"
        :class="{ 'text-black': !Boolean(item.hasParent) }"
      >{{ t(item.title) }}</span>
      <b-badge
        v-if="item.title === 'Kendala' && followUp!==0"
        pill
        :variant="'primary'"
        class="mr-1 ml-auto"
      >
        {{ followUp }}
      </b-badge>
      <b-badge
        v-if="item.title === 'Inbox'"
        pill
        :variant="'primary'"
        class="mr-1 ml-auto"
        :style="isActive ? 'background: white!important; color: #F95031!important;' : ''"
      >
        {{ countInbox.length }}
      </b-badge>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      followUp: 0,
      inboxItems: [],
      countInbox: 0,
      komformBadge: JSON.parse(localStorage.getItem('komform-badge')),
      komcardsBadge: JSON.parse(localStorage.getItem('komcards-badge')),
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to?.meta.resource !== from?.meta.resource && this.item.title === 'Kendala') {
          this.$http_komship.get('/v1/ticket-partner/count/need-followup').then(response => {
            this.followUp = response.data.data.need_followup ?? 0
          })
        }
        if (to?.meta.resource !== from?.meta.resource && this.item.title === 'Inbox') {
          this.$http_komship.get('/v1/partner-inbox/list').then(response => {
            this.countInbox = response.data.data.filter(item => item.is_read === 0)
            this.inboxItems = response.data.data
          })
        }
        if (this.komformBadge === null && this.userData.is_komform === 1) {
          localStorage.setItem('komform-badge', true)
        }
        if (this.komcardsBadge === null && this.userData.is_komcards === 1) {
          localStorage.setItem('komcards-badge', true)
        }
        if (to?.meta.resource !== from?.meta.resource && to.path === '/form-order') {
          if (this.komformBadge === true) {
            localStorage.setItem('komform-badge', false)
            this.komformBadge = false
          }
        }
        if (to?.meta.resource !== from?.meta.resource && to.path === '/komcards') {
          if (this.komcardsBadge === true) {
            localStorage.setItem('komcards-badge', false)
            this.komcardsBadge = false
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item,
    )
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
}
</script>

<style scoped>
.komform-highlight {
  border-radius: 6px;
  border: 1px solid #F08519;
}
</style>
