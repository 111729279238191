<!-- eslint-disable vue/no-v-html -->
<template>
  <li
    v-if="canViewVerticalNavMenuGroup(item) && item.visible"
    class="nav-item has-sub"
    :class="{
      open: isOpen,
      disabled: item.disabled,
      'sidebar-group-active': isActive,
    }"
  >
    <b-link
      class="d-flex align-items-center"
      :class="{ 'childtitlestyle': Boolean(item.hasParent) }"
      @click="() => updateGroupOpen(!isOpen)"
    >
      <div
        v-if="item.customicon"
        v-html="item.customicon"
      />
      <feather-icon
        v-else
        :icon="item.icon || 'CircleIcon'"
      />
      <span class="menu-title text-truncate text-black font-weight-bold">{{ t(item.title) }}</span>
      <div
        v-if="isBadgeKomform"
        class="badge-komform"
      >
        Baru
      </div>
      <div
        v-if="isBadgeKomcards"
        class="badge-komform"
      >
        Baru
      </div>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse
      v-model="isOpen"
      class="menu-content"
      tag="ul"
    >
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.header || child.title"
        ref="groupChild"
        :item="child"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse } from 'bootstrap-vue'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'

// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'

export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isBadgeKomform() {
      return ['Orderku'].includes(this.item.title) && Boolean(this.komformBadge)
    },
    isBadgeKomcards() {
      return ['Keuangan'].includes(this.item.title) && Boolean(this.komcardsBadge)
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to?.meta.resource !== from?.meta.resource && to.path === '/form-order') {
          if (this.komformBadge === true) {
            this.komformBadge = false
          }
        }
        if (to?.meta.resource !== from?.meta.resource && to.path === '/komcards') {
          if (this.komcardsBadge === true) {
            this.komcardsBadge = false
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  setup(props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    } = useVerticalNavMenuGroup(props.item)

    const komformBadge = JSON.parse(localStorage.getItem('komform-badge'))
    const komcardsBadge = JSON.parse(localStorage.getItem('komcards-badge'))

    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuGroup } = useAclUtils()

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuGroup,

      // i18n
      t,
      komformBadge,
      komcardsBadge,
    }
  },
}
</script>

<style>
.badge-komform {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #F08519;
  margin-left: 10px;
  background: #FEF3E8;
  border: 1px solid #FAD9B8;
  border-radius: 8px;
  padding: 2px 6px;
}
</style>
